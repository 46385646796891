<template>
  <div>
    <h3>Brand Manager</h3>
    <v-btn
        text
        @click="addBrand"
        outlined
        class="btn btn-lg btn-add-main"
        style="background:transparent;"
    >
      <i class="fas fa-plus-circle"></i> Add Brand
    </v-btn>
    <KTPortlet class="mt-2">
      <template v-slot:body>
        <v-app>
          <v-row>
            <div class="row" v-if="loading">
              <div class="col-md-12">
                <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
              </div>
            </div>
            <div class="row" v-if="!loading && data && data.length<=0">
              <div class="col-md-12 text-center">
                <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                <h5 class="mt-10">Looks like you have not added any data here. Trying adding
                  some data.</h5>
              </div>
            </div>
<!--            <pre>{{loading}}</pre>-->
<!--            <pre>{{data}}</pre>-->
            <v-col sm="12" md="12" lg="12" v-else>
              <v-data-table :headers="headers" :items="data"  hide-default-footer>
                <template v-slot:item.is_active="{item}">
                <span class="badge badge-success" v-if="item.is_active">
                  <i class="fas fa-check"></i>
                </span>
                  <span class="badge badge-danger" v-else>
                  <i class="fas fa-ban"></i>
                </span>
                </template>
                <template v-slot:item.action="{item}">
                <span class="m-2 cursor-pointer">
                  <i class="fas fa-edit" @click="editBrand(item)"></i>
                </span>
                  <span class="m-2 cursor-pointer">
                  <i class="fas fa-trash" @click="deleteBrand(item.id)"></i>
                </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-dialog scrollable v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="headline">Brand</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col sm="6" md="6" lg="6">
                    <v-text-field outlined dense v-model="brand.title" label="Title"></v-text-field>
                    <span class="text-danger" v-if="$v.brand.title.$error">Title is required</span>
                  </v-col>
                  <v-col sm="6" md="6" lg="6">
                    <v-file-input label="Logo/Image" outlined dense v-model="file"></v-file-input>
                  </v-col>
                  <v-col sm="12" md="12" lg="12">
                    <v-textarea outlined dense v-model="brand.description"
                                label="Description"></v-textarea>
                    <span
                        class="text-danger" outlined
                        v-if="$v.brand.description.$error"
                    >Description is required</span>
                  </v-col>
                  <v-col sm="12" md="12" lg="12">
                    <v-switch v-model="brand.is_active" label="Status"></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="text-black" text @click="resetBrand">Close</v-btn>

                <v-btn class="text-white v-btn btn-save-popup" text :loading="loading" @click="createOrUpdate">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-app>
      </template>
    </KTPortlet>
  </div>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import EcommerceBrand from "@/services/store/EcommerceBrandService";

    const brandService = new EcommerceBrand();

    export default {
        name: "brand-manager",
        validations: {
            brand: {
                title: {required},
                description: {required}
            }
        },
        components: {
            KTPortlet
        },
        data() {
            return {
                file: null,
                loading: true,
                brand: {
                    title: null,
                    description: null,
                    is_active: 1
                },
                dialog: false,
                headers: [
                    {text: "Title", value: "title"},
                    {text: "Description", value: "description"},
                    {text: "Status", value: "is_active"},
                    {text: "Action", value: "action"}
                ],
                data: []
            };
        },
        mounted() {
            this.getBrands();
        },
        methods: {
            getBrands() {
                brandService
                    .paginate()
                    .then(response => {
                        this.data = response.data.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            addBrand() {
                this.dialog = true;
            },
            editBrand(item) {
                this.dialog = true;
                this.edit = true;
                this.brand = item;
            },
            createOrUpdate() {
                this.$v.brand.$touch();
                this.loading = true;
                if (this.$v.brand.$error) {
                    setTimeout(() => {
                        this.loading = false;
                        this.$v.brand.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFormData();
                    if (this.edit) {
                        this.update(fd);
                    } else {
                        this.save(fd);
                    }
                }
            },
            generateFormData() {
                let fd = new FormData();
                fd.append("title", this.brand.title);
                fd.append("description", this.brand.description);
                if (this.file != null) fd.append("image", this.file);
                fd.append("is_active", this.brand.is_active ? 1 : 0);
                return fd;
            },
            save(fd) {
                brandService
                    .create(fd)
                    .then(response => {
                        this.$snotify.success("Brand added successfully");
                        this.resetBrand();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            update(fd) {
                brandService
                    .update(this.brand.id, fd)
                    .then(response => {
                        this.$snotify.success("Brand updated successfully");
                        this.resetBrand();
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            resetBrand() {
                this.brand = {
                    title: null,
                    description: null,
                    is_active: 1
                };
                this.edit = false;
                this.loading = false;
                this.dialog = false;
                this.getBrands();
            },
            deleteBrand(id) {
                // console.log(id);
                this.$confirm(
                    {
                        message: `Are you sure?`,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        /**
                         * Callback Function
                         * @param {Boolean} confirm
                         */
                        callback: confirm => {
                            if (confirm) {
                                brandService
                                    .delete(id)
                                    .then(response => {
                                        this.$snotify.success("Brand deleted successfully");
                                        this.resetBrand();
                                    })
                                    .catch(error => {
                                        // console.log(error);
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            }
                        }
                    }
                )


            }
        }
    };
</script>
